import * as React from 'react'
import {twMerge} from './twmerge'

/** @type {React.FC<React.HTMLAttributes<HTMLDivElement>>} */
const Skeleton = ({className, ...props}) => {
  return (
    <div
      className={twMerge(
        'animate-pulse rounded-md bg-primary-70/10',
        className,
      )}
      {...props}
    />
  )
}

export {Skeleton}
