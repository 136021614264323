import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import * as React from 'react'

export * from '@tanstack/react-query'

// Create a client
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: 'always',
      retry: 0,
    },
    mutations: {
      networkMode: 'always',
      retry: 0,
    },
  },
})

/**
 * @type {React.FC<{children: React.ReactNode}>}
 */
export const QueryProvider = ({children}) => {
  return (
    // Provide the client to your App
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}

export function cancelAllQueries() {
  queryClient.cancelQueries()
}
