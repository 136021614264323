import * as React from 'react'
import {TextField} from './TextField'

/**
 * @param {number} value
 * @param {{
 *    min?: number
 *    max?: number
 * }} [option]
 */
const isValidNumber = (value, option = {}) => {
  const {min, max} = option
  if (typeof value !== 'number') {
    return false
  }
  if (Number.isNaN(value)) {
    return false
  }
  if (typeof min === 'number' && value < min) {
    return false
  }
  if (typeof max === 'number' && value > max) {
    return false
  }
  return true
}

/**
 * @type {React.ForwardRefRenderFunction<
 *   HTMLInputElement,
 *   import('./type').ReditorUIKitNumberFieldProps
 * >}
 */
const NumberFieldForwardRefRender = (props, forwardRef) => {
  const {
    value,
    defaultValue,
    min,
    max,
    error,
    onChangeValue,
    ...textFieldProps
  } = props

  const [inputText, setInputText] = React.useState(() => {
    const defaultInputText =
      typeof defaultValue === 'number' && !Number.isNaN(defaultValue)
        ? String(defaultValue)
        : ''
    return value === undefined ? defaultInputText : String(value)
  })

  const valueRef = React.useRef(value)
  React.useEffect(() => {
    valueRef.current = value
  })

  return (
    <TextField
      // @ts-ignore
      ref={forwardRef}
      {...textFieldProps}
      // this component is always a "controlled component", because we need to take care the number transformation part
      // defaultValue={do not set the default value}
      value={inputText}
      onChange={(e) => {
        const text = e.target.value
        const number = +text

        if (Number.isNaN(number)) {
          setInputText(text)
        } else {
          setInputText(String(text))
          onChangeValue?.(number)
        }
        props.onChange?.(e)
      }}
      error={
        props.error ||
        (inputText === undefined || isValidNumber(+inputText, {min, max})
          ? undefined
          : 'Invalid number')
      }
    />
  )
}

export const NumberField = React.forwardRef(NumberFieldForwardRefRender)
