import {create} from 'zustand'

/**
 * @type {import('./type').TAlertDialogStore}
 */
export const useAlertDialogStore = create((set) => ({
  dialogIds: [],
  dialogMap: {},
  add: (dialogId, dialogParams) => {
    set((state) => ({
      dialogIds: [...state.dialogIds.filter((id) => id !== dialogId), dialogId],
      dialogMap: {
        ...state.dialogMap,
        [dialogId]: dialogParams,
      },
    }))
  },
  remove: (dialogId) => {
    set((state) => {
      const dialogIds = state.dialogIds.filter((id) => id !== dialogId)
      const dialogMap = {...state.dialogMap}
      delete dialogMap[dialogId]
      return {
        dialogIds,
        dialogMap,
      }
    })
  },
}))
