import {rest} from 'msw'
import {parse} from 'qs'

import {UnitEnum, SpecUnitEnum} from '@reditor/core/domains/part'
import {CMS_HOST} from '@reditor/core/config'

import {
  RequestParams,
  getRandomItemOrNull,
  getRandomItem,
  getRandomItems,
  randomDate,
  randomTime,
} from './common'

let id = 1

let items = Array(103)
  .fill(undefined)
  .map((_, index) => {
    return {
      id: id++,
      attributes: {
        customs_code: `Part customs_code ${index}`,
        material_group: `Part material_group ${index}`,
        material_number: `Part material_number ${index}`,
        material_name_cn: `Part material_name_cn ${index}`,
        material_name_en: `Part material_name_en ${index}`,
        material_name_vn: `Part material_name_vn ${index}`,
        manufacturer_model: `Part manufacturer_model ${index}`,
        spec: `Part spec ${index}`,
        spec_unit: getRandomItemOrNull(SpecUnitEnum.options),
        brand: `Part brand ${index}`,
        supplier: `Part supplier ${index}`,
        unit: getRandomItemOrNull(UnitEnum.options),
        material: `Part material ${index}`,
        photo: {data: null},
        unit_price: 200,
        purpose: `Part purpose ${index}`,
        production_certificate: `Part production_certificate ${index}`,
        comparative_content: `Part comparative_content ${index}`,
        creator: `Part creator ${index}`,
        modifier: `Part modifier ${index}`,
        // @ts-ignore
        createdAt: randomDate().toISOString(),
        // @ts-ignore
        updatedAt: randomDate().toISOString(),
      },
    }
  })
  .reverse()

export const getItems = () => items

export const handlers = [
  // Handles a GET /api/parts/{id} request
  rest.get(`${CMS_HOST}/api/parts/:id`, (req, res, ctx) => {
    const id = req.params.id
    const item = items.find((item) => item.id === Number(id))

    return res(
      ctx.delay(),
      ctx.status(200),
      ctx.json({
        data: item,
        meta: {},
      }),
    )
  }),

  // Handles a GET /api/parts request
  rest.get(`${CMS_HOST}/api/parts`, (req, res, ctx) => {
    const requestParams = RequestParams.parse(
      parse(req.url.search, {ignoreQueryPrefix: true}),
    )
    const page = requestParams.pagination.page
    const pageSize = requestParams.pagination.pageSize

    return res(
      ctx.delay(),
      ctx.status(200),
      ctx.json({
        data: items.slice((page - 1) * pageSize, page * pageSize),
        meta: {
          pagination: {
            page,
            pageSize,
            pageCount: Math.ceil(items.length / pageSize),
            total: items.length,
          },
        },
      }),
    )
  }),

  // Handles a POST /api/parts request
  rest.post(`${CMS_HOST}/api/parts`, async (req, res, ctx) => {
    const body = await req.json()
    const item = {
      id: id++,
      attributes: {
        ...body.data,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      },
    }
    items.unshift(item)

    return res(
      ctx.delay(),
      ctx.status(200),
      ctx.json({
        data: item,
        meta: {},
      }),
    )
  }),

  // Handles a PUT /api/parts/{id} request
  rest.put(`${CMS_HOST}/api/parts/:id`, async (req, res, ctx) => {
    const id = req.params.id
    const item = items.find((item) => item.id === Number(id))

    const editedItem = await req.json()

    // update item
    item &&
      Object.assign(item.attributes, {
        ...editedItem.data,
        updatedAt: new Date().toISOString(),
      })

    return res(
      ctx.delay(),
      ctx.status(200),
      ctx.json({
        data: item,
        meta: {},
      }),
    )
  }),
]
