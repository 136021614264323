import * as React from 'react'

/**
 * @description a
 * @type {React.FC<React.PropsWithChildren<{
 *   active?: boolean
 * }> & React.ButtonHTMLAttributes<HTMLButtonElement>>}
 */
export const PageNumber = ({
  children,
  active = false,
  disabled = false,
  onClick,
  ...props
}) => {
  return (
    <button
      disabled={active || disabled}
      className={`${
        active ? 'text-primary bg-grey-light font-bold' : ''
      } px-2 py-1 rounded border border-transparent hover:enabled:font-bold hover:enabled:text-primary-70 hover:enabled:border-primary-70`}
      onClick={onClick}
      {...props}>
      {children}
    </button>
  )
}
