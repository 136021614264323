import * as React from 'react'
import {Listbox} from '@headlessui/react'
import {useFloating, offset, flip, shift} from '@floating-ui/react-dom'

import {
  dropdownContentVariants,
  dropdownItemVariants,
  dropdownTriggerArrowVariants,
  dropdownTriggerVariants,
} from './variants'
import {Icon} from '../icons'
import {twMerge} from '../twmerge'
import {DropdownSearchbox, NoMatchesText, useSearchable} from './search'

const OPTION_ALL_VALUE = '__@@dropdown-all'

const OPTION_ALL = Object.freeze({
  text: 'All',
  value: OPTION_ALL_VALUE,
  id: OPTION_ALL_VALUE,
  disabled: false,
})

/**
 * @template T
 * @param {import('./type').ReditorUIKitDropdownOption<T>[]} selectedOption
 * @param {{
 *  placeholder?: string,
 *  options?: import('./type').ReditorUIKitDropdownOption<T>[],
 * }} [props]
 * @returns
 */
const renderDropdownTitleDefault = (selectedOption, props = {}) => {
  const {placeholder, options = []} = props
  // if all the options are selected, display the placeholder
  if (
    placeholder &&
    (selectedOption.length === options.length || selectedOption.length === 0)
  ) {
    return placeholder
  }
  return selectedOption.map((option) => option.text).join(', ')
}

/**
 * @template T
 * @param {import('./type').ReditorUIKitMultiDropdownProps<T>} props
 */
const MultiDropdown = ({titleForAll = 'All', ...props}) => {
  const {refs, floatingStyles} = useFloating({
    placement: 'bottom-start',
    strategy: 'absolute',
    middleware: [offset(4), flip(), shift()],
  })

  const [internalSelectedOption, setSelectedOption] = React.useState(
    () => props.value ?? props.defaultValue ?? [],
  )
  const selectedOption = props.value ?? internalSelectedOption

  const {defaultFilter, search, setSearch} = useSearchable()

  /**
   * @param {import('./type').ReditorUIKitDropdownOption<T>[]} newSelectedOption
   */
  const handleOnChange = (newSelectedOption) => {
    let nextSelectedOptions = newSelectedOption.filter(
      (o) => o.value !== OPTION_ALL.value,
    )

    // this change is caused by the press of "All option"
    if (newSelectedOption.length !== nextSelectedOptions.length) {
      if (nextSelectedOptions.length === 0) {
        // select all
        nextSelectedOptions = props.options
      } else {
        // select none
        nextSelectedOptions = []
      }
    }

    props.onChange?.(nextSelectedOptions)
    setSelectedOption(nextSelectedOptions)
  }

  const triggerRef = React.useRef(
    /** @type {HTMLButtonElement | null} */ (null),
  )
  const [menuWidth, setMenuWidth] = React.useState(0)
  React.useLayoutEffect(() => {
    if (triggerRef.current) {
      setMenuWidth(triggerRef.current.getBoundingClientRect().width)
    }
  }, [])

  const filteredOptions = props.options.filter(defaultFilter).map((option) => (
    <Listbox.Option
      key={option.id}
      value={option}
      disabled={option.disabled}
      className={twMerge(
        dropdownItemVariants({
          intent: 'multi',
        }),
        props.menuItemClassName,
      )}>
      {({selected}) => (
        <>
          <Icon
            size={16}
            icon={selected ? 'btn_checkbox_selected' : 'btn_checkbox'}
            className="text-deepBlue-40 ui-selected:text-primary ui-disabled:text-grey-light"
          />
          <span>{option.text}</span>
        </>
      )}
    </Listbox.Option>
  ))

  return (
    <Listbox
      multiple
      as={'div'}
      ref={refs.setReference}
      value={selectedOption}
      className={props.containerClassName}
      onChange={handleOnChange}
      disabled={props.disabled}>
      {({open}) => {
        const intent = open ? 'opened' : 'closed'
        const selected = selectedOption.length > 0
        return (
          <div className="flex flex-col">
            {props.label && (
              <Listbox.Label
                className={twMerge(
                  'text-grey text-c4 mb-1',
                  props.labelClassName,
                )}>
                {props.label}
              </Listbox.Label>
            )}
            <Listbox.Button
              ref={triggerRef}
              className={twMerge(
                dropdownTriggerVariants({
                  intent,
                  selected,
                }),
                props.className,
              )}>
              <span className="truncate">
                {renderDropdownTitleDefault(selectedOption, props)}
              </span>
              <Icon
                icon="btn_dropdown"
                className={dropdownTriggerArrowVariants({intent})}
                size={24}
              />
            </Listbox.Button>
            <Listbox.Options
              ref={refs.setFloating}
              className={dropdownContentVariants()}
              style={{
                ...floatingStyles,
                minWidth: menuWidth,
              }}>
              {props.searchable ? (
                <DropdownSearchbox
                  value={search}
                  placeholder={props.searchBoxPlaceholder}
                  onChange={({target: {value}}) => setSearch(value)}
                />
              ) : null}

              <div className="overflow-auto">
                {!search ? (
                  // a special option for "All value" option, please refer to the UX
                  <Listbox.Option
                    value={OPTION_ALL}
                    className={twMerge(
                      dropdownItemVariants({
                        intent: 'multi',
                      }),
                      props.menuItemClassName,
                    )}>
                    <>
                      <Icon
                        size={16}
                        icon={
                          selectedOption.length === 0
                            ? 'btn_checkbox'
                            : selectedOption.length >= props.options.length
                              ? 'btn_checkbox_selected'
                              : 'btn_checkbox_clear'
                        }
                        className={
                          selectedOption.length === 0
                            ? 'text-deepBlue-40'
                            : 'text-primary'
                        }
                      />
                      <span className="text-c1 text-black">{titleForAll}</span>
                    </>
                  </Listbox.Option>
                ) : null}
                {filteredOptions.length > 0 ? (
                  filteredOptions
                ) : props.searchable ? (
                  <NoMatchesText>{props.searchBoxNoResultsText}</NoMatchesText>
                ) : null}
              </div>
            </Listbox.Options>
          </div>
        )
      }}
    </Listbox>
  )
}

export {MultiDropdown}
