import React from 'react'
import {setupWorker} from 'msw'

import {mockHandlers} from '@reditor/client/gateways/strapi-gateway/mocks'

import RootProvider from './src/providers/RootProvider'
import PageLayoutProvider from './src/providers/PageLayoutProvider'

import './src/styles/global.css'

/** @type {import('gatsby').GatsbyBrowser['wrapPageElement']} */
export const wrapPageElement = ({element}, options) => {
  return <PageLayoutProvider>{element}</PageLayoutProvider>
}

/** @type {import('gatsby').GatsbyBrowser['wrapRootElement']} */
export const wrapRootElement = ({element}, options) => {
  return <RootProvider>{element}</RootProvider>
}

/** @type {import('gatsby').GatsbyBrowser["onClientEntry"]} */
export const onClientEntry = () => {
  console.log('client start')

  // if (process.env.NODE_ENV === 'development') {
  // const worker = setupWorker(...mockHandlers)
  // worker.start({
  //   onUnhandledRequest: 'bypass',
  // })
  // }
}
