import React from 'react'
import ReactDatePicker from 'react-datepicker'

import {TextField} from '../input'
import {IconButton} from '../icons'
import {twMerge} from '../twmerge'

import './style.css'

/**
 * @param {import('react-datepicker').ReactDatePickerCustomHeaderProps & {
 *    title?: import('./type').ReditorUIKitDatePickerProps['title']
 *  }} props
 */
const DatePickerCustomHeader = ({title, ...props}) => (
  <div className="flex flex-col text-c4">
    {title ? <span className="font-bold mb-1 px-1">{title}</span> : null}
    <div className="flex flex-row items-center justify-between bg-white">
      <IconButton
        icon="btn_previous"
        buttonClassName="p-1 mr-4"
        className="text-black hover:text-primary-70 disabled:text-grey-30"
        disabled={props.prevMonthButtonDisabled}
        onClick={props.decreaseMonth}
      />
      <span className="flex justify-between items-center rounded p-1 cursor-default">
        {`${props.date.toLocaleString(undefined, {
          month: 'long',
        })} ${props.date.getFullYear()}`}
      </span>
      <IconButton
        icon="btn_next"
        buttonClassName="p-1 ml-4"
        className="text-black hover:text-primary-70 disabled:text-grey-30"
        disabled={props.nextMonthButtonDisabled}
        onClick={props.increaseMonth}
      />
    </div>
    <div className="h-px my-2 bg-grey-light" />
  </div>
)

/**
 * @type {import('./type').ReditorUIKitDatePickerProps['calendarContainer']}
 */
const customDatePickerCalendarContainer = (props) => (
  <div
    className={twMerge(
      'bg-white p-4 border border-grey-light shadow-lg rounded-lg',
      props.className,
    )}>
    {props.children}
  </div>
)

/**
 * @type {import('./type').ReditorUIKitDatePickerProps['renderDayContents']}
 */
const customRenderDayContent = (day) => (
  <>
    <span className="react-datepicker__day--start-background" />
    <span className="react-datepicker__day--end-background" />
    <span className="react-datepicker__day--wrapper">{day}</span>
  </>
)

/**
 * @template {string} [T=never]
 * @template {boolean|undefined} [B=undefined]
 * @param {import('./type').ReditorUIKitDatePickerProps<T,B>} props
 */
export const DatePicker = ({
  inputProps,
  title,
  label,
  containerClassName,
  labelClassName,
  ...props
}) => {
  const reactDatePicker = (
    <ReactDatePicker
      customInput={<TextField {...inputProps} />}
      renderCustomHeader={(headerProps) => (
        <DatePickerCustomHeader title={title} {...headerProps} />
      )}
      calendarContainer={customDatePickerCalendarContainer}
      renderDayContents={customRenderDayContent}
      popperClassName="m-2 z-filter"
      dateFormat="yyyy/MM/dd"
      {...props}
    />
  )

  if (!label) return reactDatePicker

  return (
    <div className={twMerge('flex flex-col gap-1', containerClassName)}>
      <label
        // @ts-ignore
        htmlFor={props.id}
        className={twMerge('text-grey text-c4', labelClassName)}>
        {label}
      </label>
      {reactDatePicker}
    </div>
  )
}
