import * as React from 'react'
import {Switch as HeadlessSwitch} from '@headlessui/react'

import {twMerge} from '../twmerge'

/**
 * @type {React.FC<import('./type').ReditorUIKitSwitchProps>}
 */
export const Switch = ({
  defaultChecked = false,
  checked,
  onChange,
  disabled = false,
  className,
  label,
  labelClassName,
  containerClassName,
}) => {
  return (
    <HeadlessSwitch.Group
      as="div"
      className={twMerge('flex flex-col', containerClassName)}>
      {label ? (
        <HeadlessSwitch.Label
          className={twMerge('text-grey text-c4 mb-1', labelClassName)}>
          {label}
        </HeadlessSwitch.Label>
      ) : null}
      <HeadlessSwitch
        disabled={disabled}
        defaultChecked={defaultChecked}
        checked={checked}
        onChange={disabled ? undefined : onChange}
        className={twMerge(
          `ui-checked:bg-primary-70 ui-not-checked:bg-grey-70 relative inline-flex h-6 w-11 items-center rounded-full`,
          className,
        )}>
        <span
          className={`ui-checked:translate-x-6 ui-not-checked:translate-x-1 inline-block h-4 w-4 transform rounded-full bg-white transition`}
        />
      </HeadlessSwitch>
    </HeadlessSwitch.Group>
  )
}
