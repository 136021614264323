import {cva} from 'class-variance-authority'

export const dropdownContainerVariants = cva([])
export const dropdownTriggerArrowVariants = cva('min-w-fit', {
  variants: {
    intent: {
      opened: ['text-deepBlue-40', 'rotate-180'],
      closed: ['text-grey-light', 'group-hover:text-deepBlue-40'],
    },
  },
})
export const dropdownTriggerVariants = cva(
  [
    'group',
    'flex',
    'flex-row',
    'items-center',
    'justify-between',
    'gap-2',
    'py-1.5',
    'px-3',
    'rounded',
    'text-c2',
    'bg-white',
    'text-grey',
    'border',
    'border-solid',
    'select-none',
  ],
  {
    variants: {
      intent: {
        closed: ['border-grey-light', 'hover:border-primary'],
        opened: ['border-primary'],
      },
      selected: {
        true: ['text-grey'],
        false: ['text-grey-40'],
      },
    },
    defaultVariants: {
      intent: 'closed',
      selected: false,
    },
  },
)

export const dropdownContentVariants = cva([
  'absolute z-20',
  'flex',
  'flex-col',
  'rounded-lg',
  'shadow-lg',
  'py-2',
  'border',
  'border-solid',
  'border-grey-light',
  'max-h-[240px]',
  'text-c2',
  'bg-white',
  'w-min',
])

export const dropdownItemVariants = cva(
  [
    'px-4',
    'py-1',
    'hover:bg-grey-light',
    'w-full',
    'select-none',
    'outline-none',
    'mb-1',
    'last:mb-0',
    'text-c2',
    'text-grey',
    'whitespace-nowrap',
  ],
  {
    variants: {
      intent: {
        selected: ['bg-grey-light'],
        default: ['bg-white'],
        disabled: ['bg-white', 'text-grey-40', 'hover:bg-white'],

        // for multi dropdown, we have a checkbox icon with the title in a row
        multi: 'inline-flex items-center flex-row gap-x-3 gap-y-2',
      },
    },
  },
)

export const dropdownIconVariants = cva(['mr-1.5', 'inline-block'], {
  variants: {
    selected: {
      true: ['text-primary'],
      false: ['text-grey-40'],
    },
  },
  defaultVariants: {
    selected: false,
  },
})
