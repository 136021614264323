import React from 'react'
import format from 'date-fns/format'

import {DatePicker} from './DatePicker'

/**
 * @param {string|null|undefined} dateStr
 */
const parseStringToDate = (dateStr) => {
  const result =
    typeof dateStr === 'string'
      ? dateStr.length
        ? new Date(dateStr)
        : null
      : null
  result?.setHours(0)
  return result
}

/**
 * @template {string} [T=never]
 * @template {boolean|undefined} [B=undefined]
 * @param {import('./type').ReditorUIKitDateStringPickerProps<T,B>} props
 */
export const DateStringPicker = ({
  selected: selectedProp,
  startDate: startDateProp,
  endDate: endDateProp,
  dateFormat = 'yyyy-MM-dd',
  onChange: onChangeProp,
  ...props
}) => {
  const selected = parseStringToDate(selectedProp)
  const startDate = parseStringToDate(startDateProp)
  const endDate = parseStringToDate(endDateProp)

  // signals
  const onChangePropRef = React.useRef(onChangeProp)
  React.useEffect(() => {
    onChangePropRef.current = onChangeProp
  })

  /** @type {import('./type').ReditorUIKitDatePickerProps<T,B>["onChange"]} */
  const onChange = React.useCallback(
    (date, ...param) => {
      if (typeof onChangePropRef.current !== 'function') return

      /**
       * parse date to string, and pass to onChange
       */
      if (date === null) {
        // @ts-ignore
        onChangePropRef.current(null, ...param)
      } else if (date instanceof Date) {
        // @ts-ignore
        onChangePropRef.current(format(date, dateFormat), ...param)
      } else {
        onChangePropRef.current(
          // @ts-ignore
          date.map((d) => (d ? format(d, dateFormat) : null)),
          ...param,
        )
      }
    },
    [dateFormat],
  )

  return (
    <DatePicker
      {...props}
      dateFormat={dateFormat}
      selected={selected}
      startDate={startDate}
      endDate={endDate}
      onChange={onChange}
    />
  )
}
