import React from 'react'
import {
  FloatingArrow,
  arrow,
  flip,
  offset as floatingOffset,
  shift,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
} from '@floating-ui/react'
import {twMerge} from '../twmerge'

/**
 * @type {React.FC<import('./type').ReditorUIKitTooltipProps>}
 */
export const Tooltip = ({
  children,
  contentClassName,
  triggererClassName,
  content,
  arrowClassName,
  placement = 'top',
  offset = 10,
}) => {
  const arrowRef = React.useRef(null)
  const [isOpen, setIsOpen] = React.useState(false)
  const {refs, floatingStyles, context} = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      floatingOffset(offset),
      flip(),
      shift(),
      arrow({
        element: arrowRef,
      }),
    ],
    placement,
  })

  const hover = useHover(context)
  const focus = useFocus(context)

  const {getReferenceProps, getFloatingProps} = useInteractions([hover, focus])
  return (
    <>
      <div
        className={twMerge('w-fit h-fit', triggererClassName)}
        ref={refs.setReference}
        {...getReferenceProps()}>
        {children}
      </div>
      {isOpen && (
        <div
          className={twMerge(
            'px-4 py-3.5 shadow-lg rounded-lg border border-grey-light bg-white text-black',
            contentClassName,
          )}
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}>
          <FloatingArrow
            className={twMerge(
              '[&>path:first-of-type]:stroke-grey-light fill-white',
              arrowClassName,
            )}
            strokeWidth={0.5}
            ref={arrowRef}
            context={context}
          />
          {content}
        </div>
      )}
    </>
  )
}
