import * as React from 'react'
import {StaticImage} from 'gatsby-plugin-image'
import {Link} from 'gatsby-plugin-react-i18next'

import {getVisitorFromLocation, getStateFromLocation} from '../lib/visitor-auth'

const links = Object.freeze([{label: 'Parts', to: '/'}])

/**
 * @type {React.FC<{
 *   hideLogo?: boolean
 *   hideLinks?: boolean
 *   location: import('../lib/visitor-auth').Location
 * }>}
 */
const PageHeader = ({hideLinks = false, location}) => {
  return (
    <header className="z-header h-header sticky top-0 left-0 right-0 bg-white border-b border-b-grey-light">
      <nav className="container mx-auto flex flex-row h-full">
        <ul className="flex flex-row justify-center items-center space-x-4">
          <StaticImage
            height={40}
            alt="Logo"
            layout="fixed"
            placeholder="blurred"
            src="../images/Reditor.png"
          />

          {hideLinks
            ? null
            : links.map((link) => {
                return (
                  <Link
                    placeholder=""
                    key={link.label}
                    to={link.to}
                    state={getStateFromLocation(location)}
                    className="text-grey-70 text-base font-normal p-1 transition-shadow"
                    activeClassName="!text-black font-semibold">
                    {link.label}
                  </Link>
                )
              })}
        </ul>
      </nav>
    </header>
  )
}

export default PageHeader
