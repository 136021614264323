import * as React from 'react'
import {ErrorBoundary} from 'react-error-boundary'

import {QueryProvider} from '../lib/react-query'
import DefaultPageFallback from '../components/DefaultPageFallback'

/**
 * @param {{children: React.ReactElement}} param0
 */
export default function RootProvider({children}) {
  return (
    <ErrorBoundary
      FallbackComponent={DefaultPageFallback}
      onError={(error, info) => {
        console.log('boundary catch', error, info)
      }}>
      <QueryProvider>{children}</QueryProvider>
    </ErrorBoundary>
  )
}
