import React from 'react'

import {Dropdown} from '../dropdown'
import {_generateTimeOptions} from './generator'

/**
 * @type {React.FC<import("./type").ReditorUIKitTimePickerProps>}
 */
export const TimePicker = ({
  hour24,
  interval,
  className,
  onChange,
  withIcon,
  placeholder = 'Choose time',
  defaultValue,
  label,
  shouldDisableTime,
  shouldExcludeTime,
  searchable,
}) => {
  const timeOptions = React.useMemo(
    () =>
      _generateTimeOptions(
        hour24,
        interval,
        shouldDisableTime,
        shouldExcludeTime,
      ),
    [hour24, interval, shouldDisableTime, shouldExcludeTime],
  )

  const [_value, _setValue] = React.useState(() =>
    timeOptions.find((o) => o.value === defaultValue),
  )

  /**
   * @type {import('ui-kit/dropdown/type').ReditorUIKitSingleDropdownProps['onChange']}
   */
  const handleChange = (option) => {
    _setValue(option)
    onChange?.(option.value)
  }

  return (
    <Dropdown
      label={label}
      value={_value}
      className={className}
      options={timeOptions}
      onChange={handleChange}
      placeholder={placeholder}
      searchable={searchable}
      icon={withIcon ? 'ic_clock' : undefined}
    />
  )
}
