import * as React from 'react'
import {Switch} from '@headlessui/react'

import {Icon} from '../icons'

/**
 * @type {React.FC<import('./type').ReditorUIKitCheckboxProps>}
 */
export const Checkbox = ({label, ...switchProps}) => {
  return (
    <Switch.Group>
      <div className="flex items-center justify-between">
        <Switch {...switchProps}>
          {({checked}) => (
            <Icon
              size={16}
              icon={checked ? 'btn_checkbox_selected' : 'btn_checkbox'}
              className="ui-checked:text-primary hover:text-primary ui-not-checked:text-deepBlue-40 ui-disabled:text-grey-light"
            />
          )}
        </Switch>
        {label && <Switch.Label className="ml-2">{label}</Switch.Label>}
      </div>
    </Switch.Group>
  )
}
