import React from 'react'
import {TextField} from './TextField'
import {Icon} from '../icons'

export const SearchBar = React.forwardRef(
  (
    /** @type {import('./type').ReditorUIKitSearchBarProps} */ props,
    forwardedRef,
  ) => (
    <TextField
      {...props}
      inputContainerClassName="px-2.5 py-1.5"
      ref={forwardedRef}
      icon={<Icon icon="ic_search" size={24} />}
    />
  ),
)
