import * as React from 'react'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'

import {ToastContainer} from '../lib/toast'
import {AlertDialogContainer} from '../lib/alert-dialog'
import {AlertDialog} from '../ui-kit'

/**
 * @param {{children: React.ReactElement}} param0
 */
export default function PageProvider({children}) {
  return (
    <>
      {children}
      <ToastContainer />
      <AlertDialogContainer component={AlertDialog} />
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  )
}
