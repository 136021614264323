import React from 'react'
import {Dialog} from '@headlessui/react'

import {twMerge} from '../twmerge'
import {Button} from '../button'
import {H1} from '../typography'

import {
  dialogActionsVariants,
  dialogContainerVariants,
  dialogPanelContainerVariants,
  dialogPanelVariants,
} from './variants'

/**
 * @type {React.FC<import('./type').ReditorUIKitBaseDialogProps>}
 */
export const BaseDialog = ({
  title,
  className,
  children,
  actions,
  onClose,
  ...props
}) => {
  return (
    <Dialog
      className={twMerge(dialogContainerVariants(), className)}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClose={() => {}} // Prevents the dialog from closing when clicking outside of it
      {...props}>
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      {/* Full-screen container to center the panel */}
      <div className={dialogPanelContainerVariants()}>
        {/* The actual dialog panel  */}
        <Dialog.Panel className={dialogPanelVariants()}>
          {title ? (
            typeof title === 'string' ? (
              <BaseDialogTitle>{title}</BaseDialogTitle>
            ) : (
              title
            )
          ) : null}
          {children}
          <BaseDialogActions onClose={onClose} actions={actions} />
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}

/**
 * @type {React.FC<{children: string}>}
 */
const BaseDialogTitle = ({children}) => (
  <Dialog.Title as={H1} className="mb-[2.625rem]">
    {children}
  </Dialog.Title>
)

/**
 * @type {React.FC<import('./type').ReditorUIKitBaseActionsProps>}
 */
const BaseDialogActions = ({actions, onClose}) => {
  const actionNumbers = actions.length > 1 ? 'multiple' : 'single'
  return (
    <div
      className={dialogActionsVariants({
        actionNumbers,
      })}>
      {actions.map(
        ({shouldCloseDialog, onClick, text, className, ...buttonProps}) => (
          <Button
            key={text}
            className={twMerge('mr-4 last:mr-0', className)}
            onClick={async (e) => {
              await onClick?.(e)
              if (shouldCloseDialog) {
                onClose(false)
              }
            }}
            {...buttonProps}>
            {text}
          </Button>
        ),
      )}
    </div>
  )
}
