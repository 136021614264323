module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en"],"defaultLanguage":"en","fallbackLanguage":"en","trailingSlash":"always","i18nextOptions":{"ns":["index"],"defaultNS":"index","interpolation":{"escapeValue":false},"fallbackLng":"en","returnNull":false},"generateDefaultLanguagePage":false,"pages":[{"matchPath":"/error","languages":["en"]},{"matchPath":"/404","languages":["en"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
