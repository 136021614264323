// A function that receives:
// `hour24` - a boolean indicating whether the picker is in 24-hour mode or not, defaulting to true
// `interval` - a number indicating the interval between each time option (in minute), defaulting to 15
// and returns an array of times in the format of `['HH:mm', 'HH:mm', ...]`

/**
 *
 * @param {boolean} hour24
 * @param {number} interval
 * @param {import("./type").ReditorUIKitTimePickerProps['shouldDisableTime']} shouldDisableTime
 * @param {import("./type").ReditorUIKitTimePickerProps['shouldExcludeTime']} shouldExcludeTime
 * @returns {import("ui-kit/dropdown/type").ReditorUIKitDropdownOption<string>[]}
 */
export const _generateTimeOptions = (
  hour24 = true,
  interval = 15,
  shouldDisableTime,
  shouldExcludeTime,
) => {
  /**
   * @type {import("ui-kit/dropdown/type").ReditorUIKitDropdownOption<string>[]}
   */
  const times = []
  const start = 0
  const end = 23
  for (let i = start; i <= end; i++) {
    for (let j = 0; j < 60; j += interval) {
      const hour = `${i}`.padStart(2, '0')
      const minute = `${j}`.padStart(2, '0')
      const value = `${hour}:${minute}:00.000`
      times.push({
        id: value,
        text: hour24
          ? `${hour}:${minute}`
          : `${i <= 12 ? i : i - 12}:${minute} ${i < 12 ? 'AM' : 'PM'}`,
        value: value,
        disabled: shouldDisableTime?.(value),
      })
    }
  }
  if (typeof shouldExcludeTime == 'function')
    return times.filter((t) => !shouldExcludeTime(t.value))
  return times
}
