import {useAlertDialogStore} from './store'

const DEFAULT_ALERT_DIALOG_ID = '#ALERT_DIALOG'

/**
 * @param {Omit<import("./type").TAlertDialogFnParams, 'id'>} params
 */
export const showAlertDialog = (params) => {
  useAlertDialogStore
    .getState()
    .add(DEFAULT_ALERT_DIALOG_ID, {id: DEFAULT_ALERT_DIALOG_ID, ...params})
}

/**
 *
 * @param {string} id
 * @param {Omit<import("./type").TAlertDialogFnParams, 'id'>} params
 */
export const showAlertDialogWithId = (id, params) => {
  useAlertDialogStore.getState().add(id, {id, ...params})
}

export * from './container'
export * from './store'
