import React from 'react'
import {twMerge} from '../twmerge'

export const NamedAvatar = React.forwardRef(
  (
    /**
     * @type {import('./type').ReditorUIKitAvatarProps}
     */
    {name, className, ...props},
    ref,
  ) => {
    const firstLetter = name.charAt(0).toUpperCase()
    return (
      <button
        ref={ref}
        className={twMerge(
          'flex justify-center items-center w-12 aspect-square rounded-full bg-grey-light text-primary text-h1 font-black',
          className,
        )}
        {...props}>
        {firstLetter}
      </button>
    )
  },
)
