import * as React from 'react'
import {RadioGroup as HLRadioGroup} from '@headlessui/react'

import {Icon} from '../icons'
import {twMerge} from '../twmerge'

/**
 * @template T
 * @param {import('./type').ReditorUIKitRadioGroupProps<T>} param0
 */
export function RadioGroup({
  label,
  options,
  labelClassName,
  containerClassName,
  ...props
}) {
  return (
    <HLRadioGroup {...props}>
      {label && (
        <HLRadioGroup.Label
          className={twMerge('block text-grey text-c4 mb-3.5', labelClassName)}>
          {label}
        </HLRadioGroup.Label>
      )}
      <div
        className={twMerge(
          'flex flex-row gap-x-6 gap-y-2 flex-wrap',
          containerClassName,
        )}>
        {options.map((option) => (
          <HLRadioGroup.Option
            key={option.id}
            value={option.value}
            as={React.Fragment}>
            {({checked}) => (
              <li
                className={`inline-flex flex-row cursor-default text-grey-70 text-c1 ui-checked:text-black items-center`}>
                <Icon
                  size={16}
                  icon={checked ? 'btn_radio_selected' : 'btn_radio'}
                  className="ui-checked:text-primary mr-2"
                />
                {typeof option.text === 'function'
                  ? option.text({checked})
                  : option.text}
              </li>
            )}
          </HLRadioGroup.Option>
        ))}
      </div>
    </HLRadioGroup>
  )
}
