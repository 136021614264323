import React from 'react'
import {
  textFieldInputContainerVariants,
  textFieldInputVariants,
} from './variants'
import {twMerge} from '../twmerge'

/**
 * @type {React.FC<import('./type').ReditorUIKitCommonInputProps & {children: React.ReactNode}>}
 */
const InputWrapper = (props) => {
  return (
    <div className={twMerge('flex flex-col gap-1', props.containerClassName)}>
      {props.label ? (
        <label
          // @ts-ignore
          htmlFor={props.id}
          className={twMerge('text-grey text-c4', props.labelClassName)}>
          {props.label}
          {props.required ? (
            <span className="text-error text-c3"> *</span>
          ) : null}
        </label>
      ) : null}
      <div
        className={twMerge(
          textFieldInputContainerVariants({
            intent: props.error ? 'error' : 'primary',
          }),
          props.inputContainerClassName,
        )}>
        {props.icon}
        {props.children}
      </div>
      {props.error && typeof props.error === 'string' ? (
        <span
          className={twMerge('text-error px-3 text-c4', props.errorClassName)}>
          {props.error}
        </span>
      ) : null}
    </div>
  )
}

export const TextField = React.forwardRef(
  (
    /** @type {import('./type').ReditorUIKitTextFieldProps} */ props,
    /** @type {import('react').ForwardedRef<HTMLInputElement>} */ forwardedRef,
  ) => (
    <InputWrapper {...props}>
      <input
        {...omit(props, [
          'error',
          'label',
          'containerClassName',
          'inputClassName',
          'inputContainerClassName',
          'labelClassName',
          'errorClassName',
          'icon',
        ])}
        ref={forwardedRef}
        className={twMerge(
          textFieldInputVariants({intent: props.error ? 'error' : 'primary'}),
          props.inputClassName,
        )}
      />
    </InputWrapper>
  ),
)

export const MultilineTextField = React.forwardRef(
  (
    /** @type {import('./type').ReditorUIKitMultilineTextFieldProps} */ props,
    forwardedRef,
  ) => (
    <InputWrapper {...props}>
      <textarea
        {...omit(props, [
          'error',
          'label',
          'containerClassName',
          'inputClassName',
          'inputContainerClassName',
          'labelClassName',
          'errorClassName',
          'icon',
        ])}
        ref={forwardedRef}
        className={twMerge(
          textFieldInputVariants({intent: props.error ? 'error' : 'primary'}),
          'h-20',
          props.inputClassName,
        )}
      />
    </InputWrapper>
  ),
)

/**
 * @template {object} T
 * @template {keyof T} K
 * @param {T} obj
 * @param {K[]} keysToOmit
 * @returns {Omit<T,K>}
 */
function omit(obj, keysToOmit) {
  const keysToOmitSet = new Set(keysToOmit)

  // @ts-ignore
  return (
    Object.keys(obj)
      // @ts-ignore
      .filter((key) => !keysToOmitSet.has(key))
      // @ts-ignore
      .reduce((newObj, key) => Object.assign(newObj, {[key]: obj[key]}), {})
  )
}
