import {z} from 'zod'

import {SpecUnit} from './spec-unit.js'
import {Unit} from './unit.js'

export const UnitEnum = z.enum(['set', 'pcs'])
export const SpecUnitEnum = z.enum(['cm', 'mm'])

/** @typedef {z.infer<typeof Part>} Part */
export const Part = z.object({
  id: z.coerce.string(),
  material_group: z.string().nullish(),
  material_number: z.string(),
  material_name_cn: z.string().nullish(),
  material_name_en: z.string().nullish(),
  manufacturer_model: z.string().nullish(),
  spec: z.string().nullish(),
  brand: z.string().nullish(),
  material: z.string().nullish(),
  part_spec_unit: SpecUnit.nullish(),
  part_unit: Unit.nullish(),
})
