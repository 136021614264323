import React from 'react'
import {Menu} from '@headlessui/react'
import {useFloating, flip, shift, arrow, offset} from '@floating-ui/react-dom'

import {twMerge} from '../twmerge'
import {NamedAvatar} from '../avatar'
import {
  arrowClassName,
  menuActionClassName,
  menuContainerClassName,
  menuProfileItemClassName,
} from './variants'

/**
 * @type {React.FC<import('./type').ReditorUIKitProfileMenuProps>}
 */
export const ProfileMenu = ({triggerer, name, email, actions}) => {
  const arrowRef = React.useRef(null)
  const {refs, floatingStyles, middlewareData} = useFloating({
    middleware: [
      offset(12),
      flip(),
      shift(),
      arrow({
        element: arrowRef,
      }),
    ],
    strategy: 'absolute',
    placement: 'bottom-end',
  })
  return (
    <Menu>
      <Menu.Button
        ref={refs.setReference}
        as={triggerer ? undefined : NamedAvatar}
        name={name}>
        {triggerer ?? undefined}
      </Menu.Button>
      <Menu.Items
        ref={refs.setFloating}
        className={menuContainerClassName}
        style={floatingStyles}>
        <Menu.Item disabled>
          {/* Name and email */}
          <div className={menuProfileItemClassName}>
            <NamedAvatar name={name} disabled />
            <div className="ml-6 flex flex-col items-start">
              <div className="text-h2 font-bold text-grey-100">{name}</div>
              <div className="text-c2 text-grey-70">{email}</div>
            </div>
          </div>
        </Menu.Item>
        {/* Logout */}
        {actions.map((action) => (
          <Menu.Item
            as="button"
            key={action.text}
            className={twMerge(menuActionClassName, action.className)}
            disabled={action.disabled}
            onClick={action.onClick}>
            {action.text}
          </Menu.Item>
        ))}
        <div
          className={arrowClassName}
          ref={arrowRef}
          style={{
            left: middlewareData.arrow?.x ?? 0,
            top: middlewareData.arrow?.y ?? 0,
          }}
        />
      </Menu.Items>
    </Menu>
  )
}
