import {useEffect, useRef} from 'react'
import {useI18next} from 'gatsby-plugin-react-i18next'
import type {PageProps as GatsbyPageProps} from 'gatsby'

import {isBrowser} from '../utils/isBrowser'

export type LocationState = {
  visitor?: string | null
  location?: string | null
}

export type PageProps = GatsbyPageProps<unknown, unknown, LocationState>

export type Location = PageProps['location']

export function getFromLocation(location: Location, key: keyof LocationState) {
  return (
    location.state?.[key] ??
    new URLSearchParams(location.search).get(key) ??
    ''
  ).trim()
}

export function getVisitorFromLocation(location: Location) {
  return getFromLocation(location, 'visitor')
}

export function getSiteLocationFromLocation(location: Location) {
  return getFromLocation(location, 'location').toUpperCase()
}

export function getStateFromLocation(location: Location) {
  return {
    visitor: getVisitorFromLocation(location),
    location: getSiteLocationFromLocation(location),
  }
}

/**
 * right now, we only check the location's search query for visitor
 */
export function useRequiredLoggedIn({location}: {location: Location}) {
  const {navigate} = useI18next()

  const callbackRefs = useRef({
    navigate,
  })
  useEffect(() => {
    callbackRefs.current = {
      navigate,
    }
  })

  useEffect(() => {
    const {visitor, location: siteLocation} = getStateFromLocation(location)
    if (!visitor || !siteLocation) {
      throw new Error('non-auth user')
    }

    // this is to sync the search string with visitor & location
    // not the best way to do it, but it works for now
    // in the future, this should be handled with proper auth
    if (isBrowser) {
      let search = new URLSearchParams(window.location.search)
      let shouldUpdate = false
      if (!search.has('visitor')) {
        shouldUpdate = true
        search.append('visitor', String(visitor))
      }
      if (!search.has('location')) {
        shouldUpdate = true
        search.append('location', String(siteLocation).toUpperCase())
      }
      if (shouldUpdate) {
        history.replaceState(
          null,
          '',
          location.pathname + '?' + search.toString(),
        )
      }
    }
  }, [location])
}
