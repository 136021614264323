import {cva} from 'class-variance-authority'

export const flexTableWrapperVariant = cva(
  ['flex flex-col overflow-y-hidden'],
  {
    variants: {
      width: {
        fit: ['w-fit'],
        full: ['w-full'],
      },
      height: {
        fit: ['h-fit'],
        full: ['h-full'],
      },
    },
    defaultVariants: {
      width: 'fit',
      height: 'fit',
    },
  },
)

export const flexTableVariant = cva(
  ['flex flex-col overflow-y-auto bg-white'],
  {
    variants: {
      width: {
        fit: [],
        full: ['w-full'],
      },
      height: {
        fit: [],
        full: ['h-full'],
      },
    },
    defaultVariants: {
      width: 'fit',
      height: 'fit',
    },
  },
)
