import {extendTailwindMerge} from 'tailwind-merge'

export const twMerge = extendTailwindMerge({
  classGroups: {
    typography: [
      'text-h1',
      'text-h2',
      'text-h3',
      'text-h4',
      'text-c1',
      'text-c2',
      'text-c3',
      'text-c4',
    ],
  },
})
