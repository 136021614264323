import {cva} from 'class-variance-authority'

export const textFieldInputContainerVariants = cva(
  [
    'flex',
    'flex-row',
    'items-center',
    'gap-1.5',
    'py-2.5',
    'px-3',
    'rounded',
    'border',
    'border-solid',
    'bg-white',
    'text-grey',
    'border-grey-light',
    'placeholder:text-grey-70',
    'disabled:border-grey-light',
    'disabled:hover:border-grey-light',
  ],
  {
    variants: {
      intent: {
        primary: ['focus-within:border-primary', 'hover:border-primary'],
        error: ['border-error'],
      },
    },
    defaultVariants: {
      intent: 'primary',
    },
  },
)

export const textFieldInputVariants = cva(
  ['appearance-none focus:outline-none', 'w-full', 'text-c1', 'h-5'],
  {
    variants: {
      intent: {
        primary: ['focus:caret-primary'],
        error: ['focus:caret-error'],
      },
    },
    defaultVariants: {
      intent: 'primary',
    },
  },
)
