import * as React from 'react'

import {twMerge} from '../twmerge'
import './type'

/**
 * @type {React.FC<import('./type').ReditorUIKitIconProps>}
 */
export const Icon = ({icon = 'btn_radio', size = 24, ...props}) => (
  <svg viewBox={`0 0 ${size} ${size}`} width={size} height={size} {...props}>
    <use xlinkHref={`/sprites.svg#icon-${icon}`}></use>
  </svg>
)

/**
 * @type {React.FC<import('./type').ReditorUIKitIconButtonProps>}
 */
export const IconButton = ({
  onClick,
  buttonClassName,
  buttonStyle,
  disabled,
  className,
  ...props
}) => {
  return (
    <button
      className={twMerge('group', buttonClassName)}
      style={buttonStyle}
      onClick={onClick}
      disabled={disabled}>
      <Icon
        className={twMerge(
          'text-deepBlue-40 group-hover:text-primary group-disabled:text-grey-light',
          className,
        )}
        {...props}
      />
    </button>
  )
}
