import {cx} from 'class-variance-authority'

const menuItemCommonClassName = cx('px-6')
const menuActionBaseClassName = cx(
  'py-4',
  'text-h3',
  'text-grey-70',
  'text-start',
  'bg-white',
  'hover:text-primary',
  'hover:bg-grey-light',
)
const menuProfileItemBaseClassName = cx(
  'py-6',
  'flex',
  'flex-row',
  'min-w-[320px]',
)

export const menuActionClassName = cx(
  menuItemCommonClassName,
  menuActionBaseClassName,
)
export const menuProfileItemClassName = cx(
  menuItemCommonClassName,
  menuProfileItemBaseClassName,
)

export const menuContainerClassName = cx(
  'rounded',
  'shadow-lg',
  'bg-white',
  'flex',
  'flex-col',
  'items-stretch',
  'divide-y',
  'divide-grey-light',
  'border',
  'border-grey-light',
)

export const arrowClassName = cx(
  'absolute',
  'h-[12px]',
  'w-[12px]',
  'border-l',
  'border-t',
  'border-grey-light',
  'bg-white',
  'rotate-45',
  '-mt-[7px]',
  'peer-hover:bg-blue-light',
)
