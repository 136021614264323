import React from 'react'
import {twMerge} from 'ui-kit/twmerge'

/**
 * @type {React.FC<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>>}
 */
export const DropdownSearchbox = ({
  className,
  placeholder = 'Search',
  ...props
}) => {
  return (
    <input
      className={twMerge(
        'border-b pb-2 mx-4 mt-1 mb-2 outline-none border-grey-light caret-primary text-black placeholder:text-grey-40 text-c2',
        className,
      )}
      // Prevents the dropdown from closing when clicking `space` on the searchbox
      onKeyDown={(e) => {
        if (e.key === ' ') {
          e.stopPropagation()
        }
      }}
      placeholder={placeholder}
      {...props}
    />
  )
}

/**
 * @type {React.FC<{children?: string}>}
 */
export const NoMatchesText = ({children = 'No matches found'}) => (
  <div className="flex justify-center items-center mx-4 my-16 text-c2 text-grey">
    {children}
  </div>
)

export const useSearchable = () => {
  const [search, setSearch] = React.useState('')

  /**
   * @template T
   * @param {import('./type').ReditorUIKitDropdownOption<T>} option
   */
  const defaultFilter = (option) => {
    return option.text.toLowerCase().includes(search.toLowerCase())
  }

  return {
    search,
    setSearch,
    defaultFilter,
  }
}
