import * as React from 'react'

import * as FlexTable from './FlexTable'
import {Skeleton} from '../skeleton'

/**
 * @param {import('./type').ReditorUIKitTableBodySkeletonProps} props
 */
export const FlexTableBodySkeleton = ({columns, ...tableBodyProps}) => {
  return (
    <FlexTable.TableBody {...tableBodyProps}>
      {Array.from({length: 30}).map((_, i) => (
        <FlexTable.TableRow key={i.toString()}>
          {columns.map((column, j) => {
            const size = column.size
            return (
              <FlexTable.TableCell
                grow={false}
                style={{width: size}}
                key={j.toString()}>
                <Skeleton className="h-4 w-full" />
              </FlexTable.TableCell>
            )
          })}
        </FlexTable.TableRow>
      ))}
    </FlexTable.TableBody>
  )
}
