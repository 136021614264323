import {rest} from 'msw'
import {parse} from 'qs'

import {CMS_HOST} from '@reditor/core/config'

import {
  RequestParams,
  getRandomItemOrNull,
  getRandomItem,
  getRandomItems,
  randomDate,
  randomTime,
} from './common'

let id = 1

let items = Array(103)
  .fill(undefined)
  .map((_, index) => {
    return {
      id: id++,
      attributes: {
        material_number: `PartsSpecificConfig material_number ${index}`,
        location: `PartsSpecificConfig location ${index}`,
        customs_code: `PartsSpecificConfig customs_code ${index}`,
        material_name_vn: `PartsSpecificConfig material_name_vn ${index}`,
        supplier: `PartsSpecificConfig supplier ${index}`,
        price: 200,
        purpose: `PartsSpecificConfig purpose ${index}`,
        production_certificate: `PartsSpecificConfig production_certificate ${index}`,
        comparative_content: `PartsSpecificConfig comparative_content ${index}`,
        creator: `PartsSpecificConfig creator ${index}`,
        modifier: `PartsSpecificConfig modifier ${index}`,
      },
    }
  })
  .reverse()

export const getItems = () => items

export const handlers = [
  // Handles a GET /api/parts-specific-configs/{id} request
  rest.get(`${CMS_HOST}/api/parts-specific-configs/:id`, (req, res, ctx) => {
    const id = req.params.id
    const item = items.find((item) => item.id === Number(id))

    return res(
      ctx.delay(),
      ctx.status(200),
      ctx.json({
        data: item,
        meta: {},
      }),
    )
  }),

  // Handles a GET /api/parts-specific-configs request
  rest.get(`${CMS_HOST}/api/parts-specific-configs`, (req, res, ctx) => {
    const requestParams = RequestParams.parse(
      parse(req.url.search, {ignoreQueryPrefix: true}),
    )
    const page = requestParams.pagination.page
    const pageSize = requestParams.pagination.pageSize

    return res(
      ctx.delay(),
      ctx.status(200),
      ctx.json({
        data: items.slice((page - 1) * pageSize, page * pageSize),
        meta: {
          pagination: {
            page,
            pageSize,
            pageCount: Math.ceil(items.length / pageSize),
            total: items.length,
          },
        },
      }),
    )
  }),

  // Handles a POST /api/parts-specific-configs request
  rest.post(`${CMS_HOST}/api/parts-specific-configs`, async (req, res, ctx) => {
    const body = await req.json()
    const item = {
      id: id++,
      attributes: body.data,
    }
    items.unshift(item)

    return res(
      ctx.delay(),
      ctx.status(200),
      ctx.json({
        data: item,
        meta: {},
      }),
    )
  }),

  // Handles a PUT /api/parts-specific-configs/{id} request
  rest.put(
    `${CMS_HOST}/api/parts-specific-configs/:id`,
    async (req, res, ctx) => {
      const id = req.params.id
      const item = items.find((item) => item.id === Number(id))

      const editedItem = await req.json()

      // update item
      item && Object.assign(item.attributes, editedItem.data)

      return res(
        ctx.delay(),
        ctx.status(200),
        ctx.json({
          data: item,
          meta: {},
        }),
      )
    },
  ),
]
