import React from 'react'
import Lottie from 'lottie-react'

import errorOccurredAnimation from '../images/lotties/error-occurred.json'

const DefaultPageFallback = () => {
  return (
    <div className="fixed inset-0 grid place-content-center">
      <Lottie
        animationData={errorOccurredAnimation}
        style={{height: 320, width: 320}}
        className="mx-auto"
      />
      <p className="font-medium text-5xl text-black mt-14">
        This page isn't working
      </p>
    </div>
  )
}

export default DefaultPageFallback
